import { createPeer } from "./create-peer";
import { StoreType } from "../../../../store";
import { GuestDisconnectedReason, WorkingMode } from "../../../../dto";
import { PeerType } from "../../../../webrtc/types";
import { findPeer, shouldRestart } from "../../../../helpers";
import { REJECTED } from "../../../../webrtc/constants";
import { connectionRejected, logMessage } from "../../../../actions";

export const onWebRtcMessage = (
  store: StoreType,
  {
    payload: message,
  }: {
    payload: {
      from: string;
      ptype: PeerType;
      type: string;
      offerResult?: string;
      reason: GuestDisconnectedReason;
      error_name?: string;
      reconnect?: boolean;
    };
  },
) => {
  const state = store.getState();
  if (message.type === "error") {
    store.dispatch(logMessage("Error message received", message));
    state.peers.forEach(p => p.end(shouldRestart(state.workingMode, p.ptype)));
  } else if (message.offerResult?.toLowerCase() === REJECTED) {
    store.dispatch(connectionRejected(message.reason, message.error_name));
    state.peers.forEach(p => p.end(message.reconnect && shouldRestart(state.workingMode, p.ptype)));
  } else if (state.workingMode === WorkingMode.Publisher || message.from === state.targetId) {
    const peer =
      findPeer(state.peers, message.from, message.ptype ?? "publisher") ??
      createPeer({
        id: message.from,
        store: store,
        secure: false,
        ptype: message.ptype,
      });

    peer.handleMessage(message);
  }
};
