import React, { FC } from "react";

import { ColorPalette } from "../../theme/ColorPalette";

type SvgProps = React.SVGProps<SVGSVGElement>;

export const SettingsIcon: FC<Readonly<{
  size?: number;
  color?: ColorPalette;
}> &
  SvgProps> = ({ size = 24, color = ColorPalette.White, ...svgProps }) => (
  <svg
    {...svgProps}
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="M8.68588 4.00001L11.2929 1.39301C11.4804 1.20554 11.7347 1.10022 11.9999 1.10022C12.265 1.10022 12.5194 1.20554 12.7069 1.39301L15.3139 4.00001H18.9999C19.2651 4.00001 19.5195 4.10536 19.707 4.2929C19.8945 4.48044 19.9999 4.73479 19.9999 5.00001V8.68601L22.6069 11.293C22.7944 11.4805 22.8997 11.7348 22.8997 12C22.8997 12.2652 22.7944 12.5195 22.6069 12.707L19.9999 15.314V19C19.9999 19.2652 19.8945 19.5196 19.707 19.7071C19.5195 19.8946 19.2651 20 18.9999 20H15.3139L12.7069 22.607C12.5194 22.7945 12.265 22.8998 11.9999 22.8998C11.7347 22.8998 11.4804 22.7945 11.2929 22.607L8.68588 20H4.99988C4.73467 20 4.48031 19.8946 4.29278 19.7071C4.10524 19.5196 3.99988 19.2652 3.99988 19V15.314L1.39288 12.707C1.20541 12.5195 1.1001 12.2652 1.1001 12C1.1001 11.7348 1.20541 11.4805 1.39288 11.293L3.99988 8.68601V5.00001C3.99988 4.73479 4.10524 4.48044 4.29278 4.2929C4.48031 4.10536 4.73467 4.00001 4.99988 4.00001H8.68588ZM5.99988 6.00001V9.51501L3.51488 12L5.99988 14.485V18H9.51488L11.9999 20.485L14.4849 18H17.9999V14.485L20.4849 12L17.9999 9.51501V6.00001H14.4849L11.9999 3.51501L9.51488 6.00001H5.99988ZM11.9999 16C10.939 16 9.9216 15.5786 9.17146 14.8284C8.42131 14.0783 7.99988 13.0609 7.99988 12C7.99988 10.9391 8.42131 9.92173 9.17146 9.17158C9.9216 8.42143 10.939 8.00001 11.9999 8.00001C13.0608 8.00001 14.0782 8.42143 14.8283 9.17158C15.5785 9.92173 15.9999 10.9391 15.9999 12C15.9999 13.0609 15.5785 14.0783 14.8283 14.8284C14.0782 15.5786 13.0608 16 11.9999 16ZM11.9999 14C12.5303 14 13.039 13.7893 13.4141 13.4142C13.7892 13.0391 13.9999 12.5304 13.9999 12C13.9999 11.4696 13.7892 10.9609 13.4141 10.5858C13.039 10.2107 12.5303 10 11.9999 10C11.4695 10 10.9607 10.2107 10.5857 10.5858C10.2106 10.9609 9.99988 11.4696 9.99988 12C9.99988 12.5304 10.2106 13.0391 10.5857 13.4142C10.9607 13.7893 11.4695 14 11.9999 14Z"
    />
  </svg>
);
