export enum ColorPalette {
  // Black = "#000000",
  White = "#ffffff",

  // Transparent = "rgba(0, 0, 0, 0)",

  Red = "#ae1919",
  // Green = "#2ba017",

  // Dark = "#121212",
}
