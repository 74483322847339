import React, { FC } from "react";

import { ColorPalette } from "../../theme/ColorPalette";

export const MicIcon: FC<Readonly<{
  size?: number;
  active?: boolean;
  color?: ColorPalette;
}>> = ({ size = 24, color = ColorPalette.White, active }) => (
  <svg
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {active && (
      <path
        fill={color}
        d="M11.9999 3C11.2043 3 10.4412 3.31607 9.87861 3.87868C9.316 4.44129 8.99993 5.20435 8.99993 6V10C8.99993 10.7956 9.316 11.5587 9.87861 12.1213C10.4412 12.6839 11.2043 13 11.9999 13C12.7956 13 13.5586 12.6839 14.1213 12.1213C14.6839 11.5587 14.9999 10.7956 14.9999 10V6C14.9999 5.20435 14.6839 4.44129 14.1213 3.87868C13.5586 3.31607 12.7956 3 11.9999 3ZM11.9999 1C12.6565 1 13.3067 1.12933 13.9133 1.3806C14.52 1.63188 15.0712 2.00017 15.5355 2.46447C15.9998 2.92876 16.3681 3.47995 16.6193 4.08658C16.8706 4.69321 16.9999 5.34339 16.9999 6V10C16.9999 11.3261 16.4731 12.5979 15.5355 13.5355C14.5978 14.4732 13.326 15 11.9999 15C10.6738 15 9.40208 14.4732 8.4644 13.5355C7.52672 12.5979 6.99993 11.3261 6.99993 10V6C6.99993 4.67392 7.52672 3.40215 8.4644 2.46447C9.40208 1.52678 10.6738 1 11.9999 1V1ZM3.05493 11H5.06993C5.31222 12.6648 6.1458 14.1867 7.41816 15.2873C8.69053 16.3879 10.3166 16.9936 11.9989 16.9936C13.6813 16.9936 15.3073 16.3879 16.5797 15.2873C17.8521 14.1867 18.6856 12.6648 18.9279 11H20.9439C20.7166 13.0287 19.8066 14.9199 18.3631 16.3635C16.9197 17.8071 15.0286 18.7174 12.9999 18.945V23H10.9999V18.945C8.97107 18.7176 7.07972 17.8074 5.63611 16.3638C4.1925 14.9202 3.28234 13.0289 3.05493 11V11Z"
      />
    )}

    {!active && (
      <path
        fill={color}
        d="M7.41105 18.3701C8.50503 19.0214 9.71348 19.4319 10.9629 19.5766V24H13.0371V19.5766C15.1411 19.3285 17.1025 18.3357 18.5996 16.761C20.0967 15.1862 21.0406 13.123 21.2764 10.9099H19.1868C19.0251 12.0873 18.6042 13.2088 17.9575 14.1849C17.3109 15.161 16.4564 15.9647 15.4623 16.5319C14.4681 17.0992 13.3617 17.4143 12.2313 17.4522C11.1008 17.4901 9.97757 17.2498 8.95107 16.7502L10.5595 15.0594C11.3319 15.2944 12.1457 15.3372 12.9365 15.1844C13.7274 15.0317 14.4736 14.6875 15.1162 14.1793C15.7589 13.671 16.2804 13.0124 16.6394 12.2557C16.9985 11.499 17.1854 10.6648 17.1853 9.819V8.08892L23 1.97364L21.5326 0.430093L1 22.0288L2.46743 23.5713L7.41105 18.3701ZM15.0769 10.3066L12.4636 13.0555C13.1212 12.9511 13.7297 12.6278 14.2 12.1331C14.6703 11.6384 14.9776 10.9983 15.0769 10.3066ZM4.35175 15.4478L5.84822 13.8748C5.32035 12.9912 4.96049 11.9876 4.81427 10.9099H2.72358C2.89476 12.5425 3.45477 14.1033 4.35175 15.4478ZM7.36957 12.2745L8.97492 10.5859C8.91892 10.3393 8.88885 10.0841 8.88885 9.819V5.45562C8.88812 4.68625 9.14511 3.94121 9.6147 3.35134C10.0843 2.76148 10.7364 2.3645 11.4566 2.23012C12.1768 2.09575 12.919 2.23258 13.5528 2.61657C14.1866 3.00056 14.6715 3.60717 14.9224 4.32987L16.4738 2.69687C15.8982 1.66342 15.0151 0.858038 13.9609 0.405065C12.9068 -0.047908 11.7402 -0.123279 10.6413 0.190587C9.54233 0.504454 8.57219 1.1901 7.8806 2.14168C7.18902 3.09325 6.81446 4.25783 6.81474 5.45562V9.819C6.81353 10.6719 7.00325 11.5131 7.36853 12.2745H7.36957Z"
      />
    )}
  </svg>
);
