export const WEB_RTC_STOP_PEERS = "WEB_RTC_STOP_PEERS";
export const WEB_RTC_CLEAR_CREDENTIALS = "WEB_RTC_CLEAR_CREDENTIALS";
export const WEB_RTC_SET_CAMERAS = "WEB_RTC_SET_CAMERAS";
export const WEB_RTC_SET_MICS = "WEB_RTC_SET_MICS";
export const WEB_RTC_UPDATE_GENERAL_SETTINGS = "WEB_RTC_UPDATE_GENERAL_SETTINGS";
export const WEB_RTC_UPDATE_LOCAL_MEDIA_CONFIG = "WEB_RTC_UPDATE_LOCAL_MEDIA_CONFIG";
export const WEB_RTC_UPDATE_MEDIA_CONSTRAINTS = "WEB_RTC_UPDATE_MEDIA_CONSTRAINTS";
export const WEB_RTC_SET_DEFAULT_MEDIA_CONSTRAINTS = "WEB_RTC_SET_DEFAULT_MEDIA_CONSTRAINTS";
export const WEB_RTC_SET_LOCAL_MEDIA_STREAM = "WEB_RTC_SET_LOCAL_MEDIA_STREAM";
export const WEB_RTC_SET_REMOTE_MEDIA_STREAM = "WEB_RTC_SET_REMOTE_MEDIA_STREAM";
export const WEB_RTC_SET_ROOM_NAME = "WEB_RTC_SET_ROOM_NAME";
export const WEB_RTC_ROOM_LEFT = "WEB_RTC_ROOM_LEFT";
export const WEB_RTC_SET_INFO = "WEB_RTC_SET_INFO";
export const WEB_RTC_SET_CREDENTIALS = "WEB_RTC_SET_CREDENTIALS";
export const WEB_RTC_SET_GUEST_LOGIN = "WEB_RTC_SET_GUEST_LOGIN";
export const WEB_RTC_SET_GUEST_PASSWORD = "WEB_RTC_SET_GUEST_PASSWORD";
export const WEB_RTC_MIC_TOGGLED = "WEB_RTC_MIC_TOGGLED";
export const WEB_RTC_CAM_TOGGLED = "WEB_RTC_CAM_TOGGLED";
export const WEB_RTC_ADD_MESSAGE = "WEB_RTC_ADD_MESSAGE";
export const WEB_RTC_SUPPORTED = "WEB_RTC_SUPPORTED";
export const WEB_RTC_UNSUPPORTED = "WEB_RTC_UNSUPPORTED";
export const WEB_RTC_SET_ENCODERS = "WEB_RTC_SET_ENCODERS";
export const WEB_RTC_SET_WORKING_MODE = "WEB_RTC_SET_WORKING_MODE";
export const WEB_RTC_SET_PREV_MEDIA_STREAM_CONSTRAINTS =
  "WEB_RTC_SET_PREV_MEDIA_STREAM_CONSTRAINTS";
export const WEB_RTC_GET_MEDIA_DEVICES = "WEB_RTC_GET_MEDIA_DEVICES";
export const WEB_RTC_UPDATE_AUDIO_CHANNELS_SETTINGS = "WEB_RTC_UPDATE_AUDIO_CHANNELS_SETTINGS";
export const WEB_RTC_SET_LOCAL_MEDIA_STREAM_ERROR = "WEB_RTC_SET_LOCAL_MEDIA_STREAM_ERROR";
export const WEB_RTC_SET_IGNORE_PREV_CONSTRAINTS = "WEB_RTC_SET_IGNORE_PREV_CONSTRAINTS";
export const WEB_RTC_GET_LOCAL_SCREEN = "WEB_RTC_GET_LOCAL_SCREEN";
export const WEB_RTC_ENUMERATE_DEVICES = "WEB_RTC_ENUMERATE_DEVICES";
export const WEB_RTC_PROCESS_LOCAL_MEDIA_STREAM = "WEB_RTC_PROCESS_LOCAL_MEDIA_STREAM";
export const WEB_RTC_GET_LOCAL_MEDIA_STREAM = "WEB_RTC_GET_LOCAL_MEDIA_STREAM";
export const WEB_RTC_SET_SIGNALING_URL = "WEB_RTC_SET_SIGNALING_URL";
export const WEB_RTC_CLEAR_SIGNALING_URL = "WEB_RTC_CLEAR_SIGNALING_URL";
