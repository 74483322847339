import React, { FC } from "react";

import { ColorPalette } from "../../theme/ColorPalette";

type SvgProps = React.SVGProps<SVGSVGElement>;

export const MaximizeIcon: FC<Readonly<{
  readonly size?: number;
  readonly color?: ColorPalette;
}> &
  SvgProps> = ({ size = 24, color = ColorPalette.White, ...svgProps }) => (
  <svg
    {...svgProps}
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={color}
      strokeWidth="2"
      d="M20 15H11C9.89543 15 9 14.1046 9 13V4C9 2.89543 9.89543 2 11 2H20C21.1046 2 22 2.89543 22 4V13C22 14.1046 21.1046 15 20 15Z"
    />
    <path
      stroke={color}
      strokeWidth="2"
      d="M5 9H4C3.46957 9 2.96086 9.21071 2.58579 9.58579C2.21071 9.96086 2 10.4696 2 11V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H13C13.5304 22 14.0391 21.7893 14.4142 21.4142C14.7893 21.0391 15 20.5304 15 20V19"
    />
  </svg>
);
