import {
  logMessage,
  processLocalMediaStream,
  setAwaiting,
  setLoading,
  setLocalMediaStreamError,
} from "../../../../actions";
import { StoreType } from "../../../../store";

export const onWebRtcGetLocalScreen = (store: StoreType) => {
  store.dispatch(setAwaiting(true));
  store.dispatch(setLoading(true));
  const state = store.getState();
  navigator.mediaDevices
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    .getDisplayMedia(state.media)
    .then((stream: MediaStream) => {
      if (state.media.audio && state.mics.length > 0) {
        navigator.mediaDevices
          .getUserMedia({ audio: state.media.audio })
          .then(audioStream => {
            const audioTracks = audioStream.getAudioTracks();
            if (audioTracks.length > 0) audioTracks.forEach(t => stream.addTrack(t));
            store.dispatch(processLocalMediaStream(stream));
          })
          .catch(error => {
            store.dispatch(logMessage("getLocalScreen get audio error", { error }));
            store.dispatch(processLocalMediaStream(stream));
          });
      } else {
        store.dispatch(processLocalMediaStream(stream));
      }
    })
    .catch((error: Error) => {
      store.dispatch(setLocalMediaStreamError(error));
      store.dispatch(setAwaiting(false));
      store.dispatch(setLoading(false));
    });
};
