import { CameraResolutions } from "../../dto";
import { resolutions } from "../../config/WebRTC";

const deviceIdSettings = (deviceId: ConstrainDOMString) => {
  return { deviceId: { exact: deviceId } };
};

export const mergeDeviceId = (
  deviceId: ConstrainDOMString,
  constraints?: boolean | MediaTrackConstraints,
): MediaTrackConstraints => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore
  return { ...(typeof constraints === "object" ? constraints : {}), ...deviceIdSettings(deviceId) };
};

export const prepareMediaConstraints = (
  defaultVideo: boolean,
  defaultAudio: boolean,
  resolution?: CameraResolutions,
  videoDeviceId?: ConstrainDOMString,
  audioDeviceId?: ConstrainDOMString,
  fps?: number,
  echoCancellation?: boolean,
): MediaStreamConstraints => {
  let video;
  if (resolution) {
    const [width, height] = resolutions[resolution];
    video =
      resolution === "default" ? true : { width: { exact: width }, height: { exact: height } };
  } else {
    video = defaultVideo;
  }
  if (fps) {
    video = { ...(typeof video === "object" ? video : {}), frameRate: { exact: fps } };
  }
  const constraints: MediaStreamConstraints = {
    video,
    audio: defaultAudio
      ? typeof echoCancellation !== "undefined"
        ? { echoCancellation }
        : defaultAudio
      : defaultAudio,
  };
  if (videoDeviceId) {
    constraints.video = mergeDeviceId(videoDeviceId, constraints.video);
  }
  if (audioDeviceId) {
    constraints.audio = mergeDeviceId(audioDeviceId, constraints.audio);
  }
  return constraints;
};

export const prepareUsedChannels = (audioChannels?: number, otherChannels?: string) => {
  let channels;
  if (audioChannels === undefined || audioChannels < 0) {
    channels = otherChannels ?? "";
  } else {
    channels = `${audioChannels * 2 + 1},${audioChannels * 2 + 2}`;
  }
  return channels;
};
