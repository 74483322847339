export const SIGNALING_SERVER_URL = process.env.REACT_APP_SIGNALING_SERVER_URL ?? "";

export const resolutions = {
  default: [0, 0],
  "320x240": [320, 240],
  "640x360": [640, 360],
  "640x480": [640, 480],
  "1280x720": [1280, 720],
  "1920x1080": [1920, 1080],
};
